<template>
  <q-card>
    <q-card-section>
      <div class="row">
        <div class="col-5" style="text-align: center">
          <q-circular-progress
            show-value
            size="80px"
            font-size="1.1rem"
            color="blue-7"
            track-color="grey-3"
            :thickness="0.22"
            :value="pct"
          >
            <span style="color: #3086fc">{{ pct }}%</span>
          </q-circular-progress>
        </div>

        <div
          class="col-7"
          style="
            border-left: 1px solid #e9e9e9;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
          "
        >
          <div
            class="row"
            style="font-size: 1rem; font-weight: bold; width: 100%"
          >
            <div class="col-6" style="padding-left: 1rem">
              <p style="margin: 0">{{ $t("common.item_22") }}:</p>
              <p style="margin: 0">{{ $t("common.item_23") }}:</p>
              <p style="margin: 0">{{ $t("common.item_24") }}:</p>
            </div>
            <div class="col-6" style="padding-left: 1rem">
              <p style="margin: 0">{{ total }}</p>
              <p style="margin: 0">{{ used }}</p>
              <p style="margin: 0; color: #3086fc">{{ unused }}</p>
            </div>
          </div>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  props: {
    init: Boolean,
  },
  data() {
    return {
      httpProcess: false,

      total: "",
      used: "",
      unused: "",
      pct: 0,

      list: [],
    };
  },
  created() {
    if (this.init) this.getData();
  },
  methods: {
    getData() {
      var self = this;
      if (self.httpProcess) return;
      self.httpProcess = true;

      self
        .$axios({
          method: "post",
          url: "/api/query/pkg/order",
        })
        .then((res) => {
          self.httpProcess = false;
          self.total = res.data.info.total;
          self.used = res.data.info.used;
          self.unused = res.data.info.unused;
          self.pct = res.data.info.pct;

          if (res.data.list && res.data.list.length) {
            self.list = res.data.list;
            self.$emit("sendList", self.list);
          }
        })
        .catch(function (error) {
          self.httpProcess = false;
          var msg;
          switch (error.response.data.code) {
            case 401:
              msg = self.$t("common.item_01");
              break;
            default:
              msg = self.$t("common.item_02");
          }
          self.$q.dialog({
            message: msg,
            ok: self.$t("common.item_03"),
          });
        });
    },
  },
};
</script>
