<template>
  <div style="padding: 1rem" v-show="!loading">
    <div
      style="
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
      "
    >
      <img style="width: 50px; height: auto" :src="switchImg()" />
      <div v-if="user.id">
        <p style="margin: 0; position: relative; top: 3px; font-size: 1rem">
          {{ user.email }}
        </p>
        <p style="margin: 0; position: relative; bottom: 3px; color: #989898">
          <span style="margin-right: 0.5rem">{{ devs.length }}</span>
          <span>{{ $t("home.item_02") }}</span>
        </p>
      </div>
      <div style="font-size: 1rem; font-weight: bold" v-else-if="dev.id">
        {{ dev.imei }}
      </div>
      <div style="font-size: 1rem; font-weight: bold" @click="goLogin" v-else>
        {{ $t("home.item_01") }}
      </div>
      <q-btn-dropdown flat no-caps>
        <q-list>
          <q-item clickable v-close-popup @click="chooseLang('en')">
            <q-item-section>
              <q-item-label>English</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-close-popup @click="chooseLang('zh')">
            <q-item-section>
              <q-item-label>简体中文</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-close-popup @click="goReset" v-if="user.id">
            <q-item-section>
              <q-item-label>{{ $t("home.item_09") }}</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-close-popup @click="goLogout" v-if="user.id">
            <q-item-section>
              <q-item-label>{{ $t("home.item_10") }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </div>

    <div style="padding: 1rem 0 0 0">
      <div
        :style="{ marginTop: devs.length > 1 ? '0.7rem' : '0' }"
        style="position: relative"
        @click="goDev"
        v-if="dev.id"
      >
        <img
          src="../assets/home-03.png"
          v-if="devs.length > 1"
          style="
            position: absolute;
            bottom: -3px;
            width: 100%;
            height: auto;
            z-index: -1;
          "
        />
        <img
          style="width: 100%; height: auto; vertical-align: bottom"
          src="../assets/home-04.png"
        />
        <div
          style="
            position: absolute;
            width: 100%;
            top: 50%;
            transform: translate(0, -50%);
            display: flex;
            flex-flow: row wrap;
            align-items: center;
          "
        >
          <img style="width: 30%; height: auto" src="../assets/home-05.png" />
          <div style="width: 70%; color: #fff">
            <p style="margin: 0; font-size: 1rem">IMEI:</p>
            <p style="margin: 0; font-size: 1rem">{{ dev.imei }}</p>
            <p style="margin: 0; font-size: 0.7rem">
              <span style="margin-right: 0.5rem"
                >{{ $t("home.item_03") }}:</span
              >
              <span style="color: #00ffff" v-if="dev.status === 0">{{
                $t("home.item_04")
              }}</span>
              <span style="color: #ff7c00" v-else>{{
                $t("home.item_05")
              }}</span>
            </p>
          </div>
        </div>
      </div>
      <div style="position: relative" @click="goQuick" v-else>
        <img
          style="width: 100%; height: auto; vertical-align: bottom"
          src="../assets/home-06.png"
        />
        <div
          style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-flow: row wrap;
            align-items: center;
          "
        >
          <img style="width: 30px; height: auto" src="../assets/home-07.png" />
          <span
            style="margin-left: 0.5rem; font-size: 1rem; font-weight: bold"
            >{{ $t("home.item_06") }}</span
          >
        </div>
      </div>
    </div>

    <p style="margin: 0.5rem 0; font-size: 1rem; font-weight: bold">
      {{ $t("home.item_07") }}
    </p>

    <div @click="goRecord"><PkgInfo :init="false" ref="PkgInfo" /></div>

    <div
      style="
        padding: 0.5rem 0 0 0;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
      "
    >
      <span style="font-size: 1rem; font-weight: bold">{{
        $t("home.item_08")
      }}</span>
      <div style="display: flex; flex-flow: row wrap; align-items: center">
        <span style="font-size: 0.5rem">{{ showCCy(currency) }}</span>
        <q-btn-dropdown flat no-caps style="padding: 0 0.5rem 0 0">
          <q-list>
            <q-item clickable v-close-popup @click="chooseCcy('HKD')">
              <q-item-section>
                <q-item-label>{{ $t("common.item_39") }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="chooseCcy('CNY')">
              <q-item-section>
                <q-item-label>{{ $t("common.item_40") }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="chooseCcy('USD')">
              <q-item-section>
                <q-item-label>{{ $t("common.item_41") }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="chooseCcy('JPY')">
              <q-item-section>
                <q-item-label>{{ $t("common.item_42") }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="chooseCcy('KRW')">
              <q-item-section>
                <q-item-label>{{ $t("common.item_43") }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="chooseCcy('EUR')">
              <q-item-section>
                <q-item-label>{{ $t("common.item_44") }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="chooseCcy('RUB')">
              <q-item-section>
                <q-item-label>{{ $t("common.item_45") }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="chooseCcy('GBP')">
              <q-item-section>
                <q-item-label>{{ $t("common.item_46") }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
        <img
          style="width: 10px; height: auto"
          @click="goCountry"
          src="../assets/home-08.png"
        />
        <span
          style="
            font-size: 0.8rem;
            font-weight: bold;
            margin: 0 5px;
            max-width: 50vw;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          "
          @click="goCountry"
          >{{ switchlang(country) }}</span
        >
        <img
          style="width: 13px; height: auto"
          @click="goCountry"
          src="../assets/home-09.png"
        />
      </div>
    </div>

    <div class="row" style="margin: 0 0 3rem 0">
      <div
        class="col-12"
        style="
          padding: 1rem;
          text-align: center;
          font-size: 1rem;
          color: #989898;
        "
        v-if="!httpProcess && (!pkgs || pkgs.length < 1)"
      >
        {{ $t("common.item_06") }}
      </div>
      <div
        class="col-4"
        style="margin: 0.7rem 0 0 0"
        v-for="(item, index) in pkgs"
        :style="{ padding: switchPadding(index) }"
        @click="switchPkg(item)"
        :key="index"
        v-else
      >
        <q-card
          style="border-radius: 10px; text-align: center"
          :class="{
            hot: index === 0,
            active: item.id === pkg.id,
          }"
        >
          <q-card-section style="padding: 1rem 0">
            <p style="margin: 0">
              <span style="font-size: 1rem; font-weight: bold">{{
                item.data
              }}</span>
              <span style="font-size: 0.5rem">/</span>
              <span style="font-size: 0.5rem">{{ item.active }}</span>
              <span style="font-size: 0.5rem">{{ $t("common.item_25") }}</span>
            </p>
            <p
              style="
                margin: 0 5px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
            >
              {{ switchlang(item) }}
            </p>

            <p
              style="
                margin: 0.3rem 0 0 0;
                font-size: 1rem;
                font-weight: bold;
                color: #3086fc;
              "
            >
              {{ switchCurrency(currency) }} {{ parseFloat(item.price) }}
            </p>
          </q-card-section>
        </q-card>
      </div>
    </div>

    <div
      style="
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;

        padding: 0.5rem 0 1rem 0;
        text-align: center;
        color: #898989;
        background-color: white;
      "
    >
      <span>Agreement</span>
      <span style="margin: 0 0.5rem">|</span>
      <span>FAQ</span>
    </div>

    <q-dialog v-model="showBuy" persistent>
      <q-card style="border-radius: 15px; min-width: 300px">
        <q-card-section style="padding: 16px 16px 0 16px">
          <p style="margin: 0">
            <span style="font-size: 1.3rem; font-weight: bold">{{
              pkg.data
            }}</span>
            <span style="margin: 0 0.5rem">/</span>
            <span>{{ pkg.active }}</span>
            <span>{{ $t("common.item_25") }}</span>
          </p>
          <p style="margin: 0">
            {{ $t("common.item_26") }}：{{ switchlang(pkg) }}
          </p>
        </q-card-section>

        <q-card-section style="padding: 16px 16px 0 16px">
          <pre
            style="
              margin: 0;
              white-space: pre-wrap;
              word-wrap: break-word;
              font-family: 'Material';
            "
            >{{ pkg.detail }}</pre
          >
          <p
            style="
              margin: 1rem 0 0 0;
              font-size: 1.1rem;
              font-weight: bold;
              color: #3086fc;
            "
          >
            {{ $t("common.item_33") }} {{ switchCurrency(currency) }}
            {{ parseFloat(pkg.price) }}
          </p>
        </q-card-section>

        <q-card-actions>
          <div class="row" style="width: 100%">
            <div class="col-6" style="text-align: center">
              <q-btn
                flat
                color="grey-7"
                no-caps
                :label="$t('common.item_04')"
                @click="cancel"
              />
            </div>
            <div class="col-6" style="text-align: center">
              <q-btn
                style="
                  color: white;
                  background-color: #3086fc;
                  padding: 0 2.3rem;
                  border-radius: 20px;
                "
                size="md"
                no-caps
                :label="$t('common.item_27')"
                @click="submit"
              />
            </div>
          </div>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<style lang="less">
.hot {
  background-image: url(../assets/home-10.png);
  background-size: 43px auto;
  background-position: top right;
  background-repeat: no-repeat;
}
.active {
  background-color: #3086fc;
}
.active p {
  color: white !important;
}
</style>

<script>
import { loadStripe } from "@stripe/stripe-js";
import PkgInfo from "@/components/pkg.vue";
export default {
  components: {
    PkgInfo,
  },
  data() {
    return {
      httpProcess: false,
      loading: false,

      user: {},
      dev: {},
      devs: [],

      country: {},
      pkg: {},
      pkgs: [],

      currency: "",
      showBuy: false,
      payWay: "stripe",

      kind: 0,
    };
  },
  mounted() {
    var self = this;
    self.loading = true;
    self.$q.loading.show({
      message: this.$t("common.item_05"),
    });

    var code = self.getUrlArg("code");
    var token = self.getUrlArg("token");
    if (code == "suc" && token) {
      self.$q
        .dialog({
          message: self.$t("common.item_28"),
          ok: self.$t("common.item_03"),
        })
        .onDismiss(() => {
          self.$buryPoint("user_buy_suc", {});
          self.stripeNotify(token);
        });
      self.$q.loading.hide();
      return;
    } else if (code == "err") {
      self.$q.dialog({
        message: self.$t("common.item_29"),
        ok: self.$t("common.item_03"),
      });
    }

    var errCode = 0;
    new Promise((resolve, reject) => {
      var imei = self.getUrlArg("imei");
      if (!imei) {
        resolve();
        return;
      }

      self
        .$axios({
          method: "post",
          url: "/api/set/imei",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          params: { imei: imei },
        })
        .then(() => {
          resolve();
        })
        .catch(function (error) {
          errCode = 1;
          reject(error);
        });
    })
      .then(() => {
        return new Promise((resolve, reject) => {
          self
            .$axios({
              method: "post",
              url: "/api/query/base/info",
            })
            .then((res) => {
              if (res.data.user) self.user = res.data.user;
              if (res.data.dev) self.dev = res.data.dev;
              if (res.data.list && res.data.list.length) {
                self.devs = res.data.list;
              }
              resolve();
            })
            .catch(function (error) {
              reject(error);
            });
        });
      })
      .then(() => {
        return new Promise((resolve, reject) => {
          self
            .$axios({
              method: "post",
              url: "/api/query/goods/list",
              headers: {
                "Content-Type":
                  "application/x-www-form-urlencoded; charset=UTF-8",
              },
              params: { kind: self.kind },
            })
            .then((res) => {
              if (res.data.country) self.country = res.data.country;
              if (res.data.currency) self.currency = res.data.currency;
              if (res.data.list && res.data.list.length) {
                self.pkgs = res.data.list;
              }
              resolve();
            })
            .catch(function (error) {
              reject(error);
            });
        });
      })
      .then(() => {
        self.loading = false;
        self.$refs.PkgInfo.getData();
        if (!self.user.id && !self.dev.id) {
          self.goLogin();
        } else if (
          !self.user.id &&
          !self.getUrlArg("imei") &&
          self.getUrlArg("mark") != "imei"
        ) {
          self.goLogin();
        }
      })
      .catch((error) => {
        var msg;
        switch (error.response.data.code) {
          case 401:
            msg = self.$t("common.item_01");
            break;
          default:
            msg = self.$t("common.item_02");
        }
        self.$q
          .dialog({
            message: msg,
            ok: self.$t("common.item_03"),
          })
          .onOk(() => {
            if (errCode == 1) {
              self.goQuick();
            }
          });
      })
      .finally(() => {
        self.$q.loading.hide();
      });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.path == "/" && from.path == "/country") {
        vm.kind = 1;
      } else {
        vm.kind = 0;
      }
    });
  },
  methods: {
    goLogin() {
      this.$router.push({ path: "/login" });
    },
    goQuick() {
      if (this.user.id) {
        window.location.href = "/quick?code=bind";
      } else {
        this.$router.push({ path: "/quick" });
      }
    },
    goCountry() {
      this.$buryPoint("user_query_country", {});
      this.$router.push({ path: "/country" });
    },
    goDev() {
      if (this.user.id) {
        this.$router.push({ path: "/device" });
      }
    },
    goOrder() {
      this.$buryPoint("user_query_order", {});
      this.$router.replace("/order");
    },
    goRecord() {
      this.$buryPoint("user_query_pkg", {});
      this.$router.push({ path: "/record" });
    },
    goReset() {
      window.location.href = "/find?code=reset";
    },
    goLogout() {
      var self = this;
      if (self.httpProcess) return;
      self.httpProcess = true;

      self
        .$axios({
          method: "post",
          url: "/api/logout/check",
        })
        .then(() => {
          self.httpProcess = false;
          self.goLogin();
        });
    },
    switchImg() {
      if (this.dev.id) {
        return require("../assets/home-02.png");
      } else {
        return require("../assets/home-01.png");
      }
    },
    switchlang(obj) {
      switch (this.$i18n.locale) {
        case "zh":
          return obj.zh_name;
        case "en":
        default:
          return obj.en_name;
      }
    },
    switchPadding(v) {
      switch ((v + 1) % 3) {
        case 1:
          return "0 0.7rem 0 0";
        case 2:
          return "0 0.3rem";
        case 0:
          return "0 0 0 0.7rem";
      }
    },
    switchCurrency: function (v) {
      switch (v) {
        case "CNY":
          return "￥";
        case "HKD":
          return "HK$";
        case "KRW":
          return "₩";
        case "JPY":
          return "¥";
        case "EUR":
          return "€";
        case "RUB":
          return "₽";
        case "GBP":
          return "£";
        case "USD":
          return "$";
        default:
          return "";
      }
    },
    switchPkg(obj) {
      var self = this;
      self.$buryPoint("user_click_pkg", {});
      self.pkg = obj;
      setTimeout(function () {
        self.showBuy = true;
      }, 500);
    },

    getUrlArg(str) {
      if (!str) return;
      var arr = document.location.toString().split(str + "=");
      if (arr.length == 2 && arr[1].length > 0) {
        var index = arr[1].indexOf("&");
        if (index > 0) {
          str = arr[1].substring(0, index);
        } else if (index == -1) {
          str = arr[1];
        } else {
          str = "";
        }
      } else {
        str = "";
      }
      return str;
    },
    async handlePay(apiKey, sessionId) {
      try {
        const stripe = await loadStripe(apiKey);
        stripe.redirectToCheckout({ sessionId: sessionId });
      } catch (error) {
        console.error(error);
      }
    },
    cancel() {
      this.pkg = {};
      this.showBuy = false;
    },
    submit() {
      var self = this;
      self.$buryPoint("user_click_buy", {});
      if (self.httpProcess) return;
      self.httpProcess = true;

      self
        .$axios({
          method: "post",
          url: "/api/pay/buy",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          params: {
            url: window.location.href.split("?")[0],
            goods_id: self.pkg.id,
            pay_way: self.payWay,
            buy_num: 1,
          },
        })
        .then((res) => {
          self.httpProcess = false;
          self.pkg = {};
          self.showBuy = false;
          switch (res.data.pay_way) {
            case "stripe":
              self.handlePay(res.data.api_key, res.data.session_id);
              break;
            case "test":
              window.location.href = res.data.url;
              break;
            default:
              self.$q.dialog({
                message: self.$t("common.item_02"),
                ok: self.$t("common.item_03"),
              });
          }
        })
        .catch(function (error) {
          self.httpProcess = false;
          self.showBuy = false;
          self.pkg = {};
          var msg;
          switch (error.response.data.code) {
            case 401:
              msg = self.$t("common.item_01");
              break;
            case 402:
              msg = self.$t("common.item_31");
              break;
            default:
              msg = self.$t("common.item_02");
          }
          self.$q.dialog({
            message: msg,
            ok: self.$t("common.item_03"),
          });
        });
    },
    stripeNotify: function (token) {
      var self = this;
      if (self.httpProcess) return;
      self.httpProcess = true;

      self
        .$axios({
          method: "post",
          url: "/api/pay/notify",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          params: {
            token: token,
          },
        })
        .then(() => {
          self.httpProcess = false;
          self.goOrder();
        })
        .catch(function (error) {
          self.httpProcess = false;
          var msg;
          switch (error.response.data.code) {
            case 401:
              msg = self.$t("common.item_01");
              break;
            default:
              msg = self.$t("common.item_02");
          }
          self.$q.dialog({
            message: msg,
            ok: self.$t("common.item_03"),
          });
        });
    },
    chooseLang(v) {
      var self = this;
      if (self.httpProcess) return;
      self.httpProcess = true;

      self
        .$axios({
          method: "post",
          url: "/api/set/lang",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          params: { lang: v },
        })
        .then(() => {
          self.httpProcess = false;
          // 本地保存 <=> 当前设置
          self.$i18n.locale = v;
          window.sessionStorage["lang"] = v;
          self.$parent.clearMsg && self.$parent.clearMsg();
          document.title = this.$t("common.item_32");
        })
        .catch(function (error) {
          self.httpProcess = false;
          var msg;
          switch (error.response.data.code) {
            case 401:
              msg = self.$t("common.item_01");
              break;
            default:
              msg = self.$t("common.item_02");
          }
          self.$q.dialog({
            message: msg,
            ok: self.$t("common.item_03"),
          });
        });
    },
    showCCy(v) {
      switch (v) {
        case "HKD":
          return this.$t("common.item_39");
        case "CNY":
          return this.$t("common.item_40");
        case "USD":
          return this.$t("common.item_41");
        case "JPY":
          return this.$t("common.item_42");
        case "KRW":
          return this.$t("common.item_43");
        case "EUR":
          return this.$t("common.item_44");
        case "RUB":
          return this.$t("common.item_45");
        case "GBP":
          return this.$t("common.item_46");
      }
    },
    chooseCcy(v) {
      var self = this;
      if (self.httpProcess) return;
      self.httpProcess = true;

      self
        .$axios({
          method: "post",
          url: "/api/set/ccy",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          params: { ccy: v },
        })
        .then(() => {
          self.httpProcess = false;
          location.reload();
          self.$router.go(0);
        })
        .catch(function (error) {
          self.httpProcess = false;
          var msg;
          switch (error.response.data.code) {
            case 401:
              msg = self.$t("common.item_01");
              break;
            default:
              msg = self.$t("common.item_02");
          }
          self.$q.dialog({
            message: msg,
            ok: self.$t("common.item_03"),
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.q-item {
  min-height: 0;
}
</style>
